<!-- 物流设置 -->
<template>
	<div class="bg-white px-3" style="margin: -20px;margin-top: -1rem; margin-bottom: 0!important;">
		<el-tabs v-model="activeName">
			<!-- 运费模板 -->
			<el-tab-pane label="运费模板" name="second">
				<div>
					<el-button type="primary" size="mini" @click="openModal(false)">添加模板</el-button>
				</div>
				<el-table border class="mt-3" :data="tableData" style="width: 100%">

					<!-- 模板名称 -->
					<el-table-column align="center" label="模板名称" prop="name">
					</el-table-column>

					<!-- 计费方式 -->
					<el-table-column align="center" width="250" label="计费方式">
						<template slot-scope="scope">
							{{ scope.row.type | formatType }}
						</template>
					</el-table-column>

					<!-- 排序 -->
					<el-table-column prop="order" align="center" label="排序">
					</el-table-column>

					<!-- 操作 -->
					<el-table-column align="center" label="操作" width="250">
						<template slot-scope="scope">
							<el-button type="text" size="mini" @click="openModal(scope.$index)">修改</el-button>
							<el-button type="text" size="mini" @click='openDrawer(scope.row)'>配置模板({{scope.row.express_values.length}})</el-button>
							<el-button type="text" size="mini" @click="deleteItem(scope.row)">删除</el-button>
						</template>
					</el-table-column>
				</el-table>
			</el-tab-pane>

			<!-- 物流跟踪 -->
			<el-tab-pane label="物流跟踪" name="third">
				<el-form ref="form" :model="form" label-width="160px">
					<el-form-item label="物流查询key">
						<el-input v-model="form.ship" size="mini" style="width: 50%;" placeholder="appkey"></el-input>
						<small class="text-secondary d-block">用于查询物流信息，<a target="_blank" href="https://www.jisuapi.com/api/express/">接口申请（仅供参考）</a></small>
					</el-form-item>
					<el-form-item>
						<el-button type="primary" size="mini" @click="save">保存</el-button>
					</el-form-item>
				</el-form>
			</el-tab-pane>
		</el-tabs>
		<div style="height: 60px;"></div>
		<el-footer class="border-top d-flex align-items-center px-0 position-fixed bg-white" style="bottom: 0;left: 200px;right: 0;z-index: 100;">
			<div style="flex: 1;" class="px-2">
				<el-pagination :current-page="page.current" :page-sizes="page.sizes" :page-size="page.size" layout="total, sizes, prev, pager, next, jumper"
				 :total="page.total" @size-change="handleSizeChange" @current-change="handleCurrentChange">
				</el-pagination>
			</div>
		</el-footer>

		<!-- 添加模板 模态框 -->
		<el-dialog title="添加模板" :visible.sync="createModal" top="5vh">
			<!-- 表单内容 -->
			<el-form ref="form" :model="modal" label-width="80px">
				<el-form-item label="模板名称" prop="name">
					<el-input v-model="modal.name" placeholder="模板名称" size="mini" style="width: 25%;"></el-input>
				</el-form-item>
				<el-form-item label="排序" prop="level">
					<el-input type="number" v-model="modal.order" placeholder="排序" size="mini" style="width: 25%;"></el-input>
				</el-form-item>
				<el-form-item label="是否启用">
					<el-radio-group v-model="modal.type" size="mini">
						<el-radio :label="1" border>按重量</el-radio>
						<el-radio :label="0" border>按件数</el-radio>
					</el-radio-group>
				</el-form-item>
			</el-form>


			<div slot="footer" class="dialog-footer">
				<el-button @click="createModal = false">取 消</el-button>
				<el-button type="primary" @click="submit">确 定</el-button>
			</div>
		</el-dialog>

		<!-- 配置模板 抽屉 -->
		<el-drawer size="70%" :visible.sync="drawer" :with-header="false">
			<div class="p-3">
				<el-table border class="mt-3" :data="express_values" style="width: 100%">
					<el-table-column align="center" label="ID" prop="id">
					</el-table-column>
					<el-table-column align="center" label="可配送区域" width="200">
						<template slot-scope="scope">
							<el-cascader size="mini" :options="options" :props="props" collapse-tags clearable v-model="scope.row.region"
							 @change="change(scope.row)"></el-cascader>
						</template>
					</el-table-column>
					<el-table-column align="center" width="150" label="首件(个)/首重(Kg)">
						<template slot-scope="scope">
							<el-input type="number" size="mini" v-model="scope.row.first" @change="change(scope.row)"></el-input>
						</template>
					</el-table-column>
					<el-table-column align="center" width="150" label="运费(元)">
						<template slot-scope="scope">
							<el-input type="number" size="mini" v-model="scope.row.first_price" @change="change(scope.row)"></el-input>
						</template>
					</el-table-column>
					<el-table-column align="center" width="150" label="续件/续重">
						<template slot-scope="scope">
							<el-input type="number" size="mini" v-model="scope.row.add" @change="change(scope.row)"></el-input>
						</template>
					</el-table-column>
					<el-table-column align="center" width="150" label="续费(元)">
						<template slot-scope="scope">
							<el-input type="number" size="mini" v-model="scope.row.add_price" @change="change(scope.row)"></el-input>
						</template>
					</el-table-column>
					<el-table-column align="center" label="操作" width="100">
						<template slot-scope="scope">
							<el-button type="text" size="mini" @click="deleteValue(scope.row,scope.$index)">删除</el-button>
						</template>
					</el-table-column>
				</el-table>

				<el-button type="primary" class="mt-3" @click="addValue">添加策略</el-button>
			</div>
		</el-drawer>
	</div>
</template>

<script>
	import common from '@/common/mixins/common.js'; // 引入 全局公共样式
	export default {
		inject: ['app', 'layout'],//依赖注入
		mixins: [common],// 引入mixins里的common.js
		data() {
			return {
				drawer: false, //不显示抽屉功能
				drawerId: 0, //记录模板id
				preUrl: "express",//api

				activeName: "second",
				tableData: [],
				form: {
					ship: ""
				},

				createModal: false,
				modal: {
					name: "", //模板名称
					order: 50, //排序
					type: 0 //计费方式（0按件数 1按重量）
				},
				modalIndex: -1,

				express_values: [], //储存配置模板数据

				props: {
					multiple: true,
					label: "name",
					value: "name"
				},
				options: []

			}
		},
		// 过滤器
		filters: {
			formatType(value) {
				let arr = ['按件数', '按重量']
				return arr[value];
			}
		},
		created() {
			this.getData()
		},
		methods: {
			getData() {
				this.layout.showLoading()
				this.axios.get('/admin/sysconfig', {
					token: true
				}).then(res => {
					this.form.ship = res.data.data.ship
				}).finally(() => {
					this.layout.hideLoading()
				})
			},
			save() {
				this.layout.showLoading()
				this.axios.post('/admin/sysconfig', this.form, {
					token: true
				}).then(res => {
					this.$message({
						type: "success",
						message: "修改成功"
					})
					this.getData()
				}).finally(() => {
					this.layout.hideLoading()
				})
			},
			// 获取列表 可修改
			getListResult(e) {
				console.log(e);
				this.tableData = e.list
				this.options = e.area.map(item => { //修改数据结构
					item.children = item.citys.map(v => {
						v.children = v.districts
						return v
					})
					return item
				})
			},
			// 添加/修改 模板
			openModal(index) {
				// 新增
				if (index === false) {
					this.modal = {
						name: "",
						order: 50,
						type: 0
					}
					this.modalIndex = -1
					return this.createModal = true
				}
				// 修改
				let item = this.tableData[index]
				this.modal = { // 初始化
					name: item.name,
					order: item.order,
					type: item.type
				}
				this.modalIndex = index
				this.createModal = true
			},
			// 提交
			submit() {
				let id = 0
				if (this.modalIndex !== -1) {
					id = this.tableData[this.modalIndex].id
				}
				this.addOrEdit(this.modal, id)
				this.createModal = false
			},
			// 打开 配置模板 抽屉
			openDrawer(item) {
				this.express_values = item.express_values
				this.drawer = true
				this.drawerId = item.id //记录模板id
			},
			// 添加策略
			addValue() {
				this.axios.post('/admin/express_value', { //添加运费模板策略 api
					first: 0, //首件（个）、首重（kg）
					first_price: 0, //运费（元）
					add: 0, //续件、续重
					add_price: 0, //续费（元）
					express_id: this.drawerId, //运费模板id
					region: [
						['全国', '所有', '所有']
					], //可配送区域
				}, {
					token: true
				}).then(res => {
					let data = res.data.data
					this.express_values.push(data)
				})
			},
			// 删除 配置模板
			deleteValue(item, index) {
				this.axios.post('/admin/express_value/' + item.id + '/delete', {}, { //删除运费模板策略 api
					token: true,
				}).then(res => {
					this.express_values.splice(index, 1)
					this.$message({
						type: "success",
						message: "删除成功"
					})
				})
			},
			// 修改 配置模板 （自动提交）
			change(item) {
				this.axios.post('/admin/express_value/' + item.id, item, { //修改运费模板策略 api
					token: true,
				}).then(res => {
					console.log(res);
				})
			}
		},
	}
</script>

<style>
</style>
